import { countries } from '@design-system/configs/countries'
import type { Country } from '@design-system/components/Footer/FooterAsideLanguage.props'

export const useCountryHelper = () => {
  const { ts } = useI18n()

  const getCountryName = (isoCode: string): string => {
    const countryNames: { [key: string]: string } = {
      ad: ts('countriesList.ad'),
      ae: ts('countriesList.ae'),
      af: ts('countriesList.af'),
      ag: ts('countriesList.ag'),
      ai: ts('countriesList.ai'),
      al: ts('countriesList.al'),
      am: ts('countriesList.am'),
      an: ts('countriesList.an'),
      ao: ts('countriesList.ao'),
      aq: ts('countriesList.aq'),
      ar: ts('countriesList.ar'),
      as: ts('countriesList.as'),
      at: ts('countriesList.at'),
      au: ts('countriesList.au'),
      aw: ts('countriesList.aw'),
      az: ts('countriesList.az'),
      ba: ts('countriesList.ba'),
      bb: ts('countriesList.bb'),
      bd: ts('countriesList.bd'),
      be: ts('countriesList.be'),
      bf: ts('countriesList.bf'),
      bg: ts('countriesList.bg'),
      bh: ts('countriesList.bh'),
      bi: ts('countriesList.bi'),
      bj: ts('countriesList.bj'),
      bm: ts('countriesList.bm'),
      bn: ts('countriesList.bn'),
      bo: ts('countriesList.bo'),
      br: ts('countriesList.br'),
      bs: ts('countriesList.bs'),
      bt: ts('countriesList.bt'),
      bv: ts('countriesList.bv'),
      bw: ts('countriesList.bw'),
      by: ts('countriesList.by'),
      bz: ts('countriesList.bz'),
      ca: ts('countriesList.ca'),
      cb: ts('countriesList.cb'),
      cc: ts('countriesList.cc'),
      cd: ts('countriesList.cd'),
      cf: ts('countriesList.cf'),
      cg: ts('countriesList.cg'),
      ch: ts('countriesList.ch'),
      ci: ts('countriesList.ci'),
      ck: ts('countriesList.ck'),
      cl: ts('countriesList.cl'),
      cm: ts('countriesList.cm'),
      cn: ts('countriesList.cn'),
      co: ts('countriesList.co'),
      cr: ts('countriesList.cr'),
      cu: ts('countriesList.cu'),
      cv: ts('countriesList.cv'),
      cx: ts('countriesList.cx'),
      cy: ts('countriesList.cy'),
      cz: ts('countriesList.cz'),
      de: ts('countriesList.de'),
      dj: ts('countriesList.dj'),
      dk: ts('countriesList.dk'),
      dm: ts('countriesList.dm'),
      do: ts('countriesList.do'),
      dz: ts('countriesList.dz'),
      ec: ts('countriesList.ec'),
      ee: ts('countriesList.ee'),
      eg: ts('countriesList.eg'),
      eh: ts('countriesList.eh'),
      er: ts('countriesList.er'),
      es: ts('countriesList.es'),
      et: ts('countriesList.et'),
      fi: ts('countriesList.fi'),
      fj: ts('countriesList.fj'),
      fk: ts('countriesList.fk'),
      fm: ts('countriesList.fm'),
      fo: ts('countriesList.fo'),
      fr: ts('countriesList.fr'),
      ga: ts('countriesList.ga'),
      gb: ts('countriesList.gb'),
      gd: ts('countriesList.gd'),
      ge: ts('countriesList.ge'),
      gf: ts('countriesList.gf'),
      gg: ts('countriesList.gg'),
      gh: ts('countriesList.gh'),
      gi: ts('countriesList.gi'),
      gl: ts('countriesList.gl'),
      gm: ts('countriesList.gm'),
      gn: ts('countriesList.gn'),
      gp: ts('countriesList.gp'),
      gq: ts('countriesList.gq'),
      gr: ts('countriesList.gr'),
      gs: ts('countriesList.gs'),
      gt: ts('countriesList.gt'),
      gu: ts('countriesList.gu'),
      gw: ts('countriesList.gw'),
      gy: ts('countriesList.gy'),
      hk: ts('countriesList.hk'),
      hm: ts('countriesList.hm'),
      hn: ts('countriesList.hn'),
      hr: ts('countriesList.hr'),
      ht: ts('countriesList.ht'),
      hu: ts('countriesList.hu'),
      id: ts('countriesList.id'),
      ie: ts('countriesList.ie'),
      il: ts('countriesList.il'),
      im: ts('countriesList.im'),
      in: ts('countriesList.in'),
      io: ts('countriesList.io'),
      iq: ts('countriesList.iq'),
      ir: ts('countriesList.ir'),
      is: ts('countriesList.is'),
      it: ts('countriesList.it'),
      je: ts('countriesList.je'),
      jm: ts('countriesList.jm'),
      jo: ts('countriesList.jo'),
      jp: ts('countriesList.jp'),
      ke: ts('countriesList.ke'),
      kg: ts('countriesList.kg'),
      kh: ts('countriesList.kh'),
      ki: ts('countriesList.ki'),
      km: ts('countriesList.km'),
      kn: ts('countriesList.kn'),
      kp: ts('countriesList.kp'),
      kr: ts('countriesList.kr'),
      kw: ts('countriesList.kw'),
      ky: ts('countriesList.ky'),
      kz: ts('countriesList.kz'),
      la: ts('countriesList.la'),
      lb: ts('countriesList.lb'),
      lc: ts('countriesList.lc'),
      li: ts('countriesList.li'),
      lk: ts('countriesList.lk'),
      lr: ts('countriesList.lr'),
      ls: ts('countriesList.ls'),
      lt: ts('countriesList.lt'),
      lu: ts('countriesList.lu'),
      lv: ts('countriesList.lv'),
      ly: ts('countriesList.ly'),
      ma: ts('countriesList.ma'),
      mc: ts('countriesList.mc'),
      md: ts('countriesList.md'),
      me: ts('countriesList.me'),
      mg: ts('countriesList.mg'),
      mh: ts('countriesList.mh'),
      mk: ts('countriesList.mk'),
      ml: ts('countriesList.ml'),
      mm: ts('countriesList.mm'),
      mn: ts('countriesList.mn'),
      mo: ts('countriesList.mo'),
      mp: ts('countriesList.mp'),
      mq: ts('countriesList.mq'),
      mr: ts('countriesList.mr'),
      ms: ts('countriesList.ms'),
      mt: ts('countriesList.mt'),
      mu: ts('countriesList.mu'),
      mv: ts('countriesList.mv'),
      mw: ts('countriesList.mw'),
      mx: ts('countriesList.mx'),
      my: ts('countriesList.my'),
      mz: ts('countriesList.mz'),
      na: ts('countriesList.na'),
      nc: ts('countriesList.nc'),
      ne: ts('countriesList.ne'),
      nf: ts('countriesList.nf'),
      ng: ts('countriesList.ng'),
      ni: ts('countriesList.ni'),
      nl: ts('countriesList.nl'),
      no: ts('countriesList.no'),
      np: ts('countriesList.np'),
      nr: ts('countriesList.nr'),
      nu: ts('countriesList.nu'),
      nz: ts('countriesList.nz'),
      om: ts('countriesList.om'),
      pa: ts('countriesList.pa'),
      pe: ts('countriesList.pe'),
      pf: ts('countriesList.pf'),
      pg: ts('countriesList.pg'),
      ph: ts('countriesList.ph'),
      pk: ts('countriesList.pk'),
      pl: ts('countriesList.pl'),
      pm: ts('countriesList.pm'),
      pn: ts('countriesList.pn'),
      pr: ts('countriesList.pr'),
      ps: ts('countriesList.ps'),
      pt: ts('countriesList.pt'),
      pw: ts('countriesList.pw'),
      py: ts('countriesList.py'),
      qa: ts('countriesList.qa'),
      re: ts('countriesList.re'),
      ro: ts('countriesList.ro'),
      rs: ts('countriesList.rs'),
      ru: ts('countriesList.ru'),
      rw: ts('countriesList.rw'),
      sa: ts('countriesList.sa'),
      sb: ts('countriesList.sb'),
      sc: ts('countriesList.sc'),
      sd: ts('countriesList.sd'),
      se: ts('countriesList.se'),
      sg: ts('countriesList.sg'),
      sh: ts('countriesList.sh'),
      si: ts('countriesList.si'),
      sj: ts('countriesList.sj'),
      sk: ts('countriesList.sk'),
      sl: ts('countriesList.sl'),
      sm: ts('countriesList.sm'),
      sn: ts('countriesList.sn'),
      so: ts('countriesList.so'),
      sr: ts('countriesList.sr'),
      st: ts('countriesList.st'),
      sv: ts('countriesList.sv'),
      sy: ts('countriesList.sy'),
      sz: ts('countriesList.sz'),
      tc: ts('countriesList.tc'),
      td: ts('countriesList.td'),
      tf: ts('countriesList.tf'),
      tg: ts('countriesList.tg'),
      th: ts('countriesList.th'),
      tj: ts('countriesList.tj'),
      tk: ts('countriesList.tk'),
      tl: ts('countriesList.tl'),
      tm: ts('countriesList.tm'),
      tn: ts('countriesList.tn'),
      to: ts('countriesList.to'),
      tp: ts('countriesList.tp'),
      tr: ts('countriesList.tr'),
      tt: ts('countriesList.tt'),
      tv: ts('countriesList.tv'),
      tw: ts('countriesList.tw'),
      tz: ts('countriesList.tz'),
      ua: ts('countriesList.ua'),
      ug: ts('countriesList.ug'),
      um: ts('countriesList.um'),
      us: ts('countriesList.us'),
      uy: ts('countriesList.uy'),
      uz: ts('countriesList.uz'),
      va: ts('countriesList.va'),
      vc: ts('countriesList.vc'),
      ve: ts('countriesList.ve'),
      vg: ts('countriesList.vg'),
      vi: ts('countriesList.vi'),
      vn: ts('countriesList.vn'),
      vu: ts('countriesList.vu'),
      wf: ts('countriesList.wf'),
      ws: ts('countriesList.ws'),
      wx: ts('countriesList.wx'),
      xk: ts('countriesList.xk'),
      ye: ts('countriesList.ye'),
      yt: ts('countriesList.yt'),
      za: ts('countriesList.za'),
      zm: ts('countriesList.zm'),
      zw: ts('countriesList.zw'),
    }

    if (Object.hasOwn(countryNames, isoCode)) return countryNames[isoCode]
    return isoCode
  }

  const getLanguageName = (languageCode: string): string => {
    const languagesList: { [key: string]: string } = {
      zh: ts('languagesList.zh'),
      de: ts('languagesList.de'),
      en: ts('languagesList.en'),
      es: ts('languagesList.es'),
      fr: ts('languagesList.fr'),
      it: ts('languagesList.it'),
      ja: ts('languagesList.ja'),
      ko: ts('languagesList.ko'),
      pt: ts('languagesList.pt'),
      z2: ts('languagesList.z2'),
    }

    if (Object.hasOwn(languagesList, languageCode))
      return languagesList[languageCode]
    return languageCode
  }

  const getContinentName = (isoCode: string): string => {
    const continentNames: { [key: string]: string } = {
      eu: ts('continentsList.eu'),
      us: ts('continentsList.us'),
      as: ts('continentsList.as'),
      au: ts('continentsList.au'),
      me: ts('continentsList.me'),
    }

    if (Object.hasOwn(continentNames, isoCode)) return continentNames[isoCode]
    return isoCode
  }

  const getLabel = (country: Country) => {
    if (country) {
      return (
        getLanguageName(country.languages[0].langcode) +
        '-' +
        country.code.toUpperCase()
      )
    }
  }

  const getLabelByCountryCode = (countryCode: string) =>
    getLabel(countries.find(country => country.code === countryCode)!)

  const getIsCountryShoppable = (countryCode: string) =>
    countries.find(country => country.code === countryCode)?.isShoppable ??
    false

  return {
    getCountryName,
    getLanguageName,
    getContinentName,
    getLabelByCountryCode,
    getIsCountryShoppable,
  }
}
